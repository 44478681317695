import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBbOa56mrJSGrkEocctvl88QP_eapJvvBo",
    authDomain: "ninja-finance-tracker.firebaseapp.com",
    projectId: "ninja-finance-tracker",
    storageBucket: "ninja-finance-tracker.appspot.com",
    messagingSenderId: "341168847723",
    appId: "1:341168847723:web:bb8dd578da2d0f4c4c2a2e",
};

// init firebase
firebase.initializeApp(firebaseConfig);

//init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();

// firebase timestamp
const timestamp = firebase.firestore.Timestamp;

// export selected services
export { projectFirestore, projectAuth, timestamp };

// import * as app from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/database';

// const config = {
//   apiKey: process.env.API_KEY,
//   authDomain: process.env.AUTH_DOMAIN,
//   databaseURL: process.env.DB_URL,
//   storageBucket: process.env.STORAGE_BUCKET,
// };

// app.initializeApp(config);

// const database = app.database();
// const auth = app.auth();

// export { database, auth };
