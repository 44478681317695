import { useEffect, useState } from "react";
import { projectAuth } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";

export const useSignup = () => {
    const [isCancelled, setIsCancelled] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const { dispatch } = useAuthContext();

    const signup = async (email, password, displayName) => {
        setError(null);
        setIsPending(true);

        try {
            //signup user
            const res = await projectAuth.createUserWithEmailAndPassword(
                email,
                password
            );

            if (!res) {
                throw new Error("Could not complete signup");
            }
            // add display name to user
            await res.user.updateProfile({ displayName });

            // TODO
            // add to new user to db as well for additional properties
            // await projectFirestore.collection("profiles").doc(res.user.uid).set({ name: 'New value', role: 2 });

            // dispatch login action
            dispatch({ type: "LOGIN", payload: res.user });

            //update state if still mounted
            if (!isCancelled) {
                setIsPending(false);
                setError(null);
            }
        } catch (err) {
            //update state if still mounted
            if (!isCancelled) {
                console.log(err.message);
                setError(err.message);
                setIsPending(false);
            }
        }
    };

    // used for cleanup
    useEffect(() => {
        return () => setIsCancelled(true);
    }, []);

    return { signup, isPending, error };
};
